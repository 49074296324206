@import '../scss/designtokens';

.bg-invisible {
  background-color: transparent;
  box-shadow: none;
}

.play-button {
  height: 5.5rem;
  width: fit-content;
  aspect-ratio: 6/2;
  position: fixed;
  right: -10px;
  bottom: 3rem;
  z-index: 4;
  span {
    position: absolute;
    font-family: 'Play', sans-serif;
    font-weight: bold;
    top: 36px;
    left: 70px;
    font-size: 1.2em;
    pointer-events: none;
  }
}

.background {
  &::before {
    content: '';
    width: 100%;
    background-color: $background-primary;
    padding: 20px 0 0 20px;
    height: 120px;
    position: fixed;
    top: 0;
    box-shadow: 0px 5px 30px 30px $background-primary;
    z-index: 1;
  }
  &::after {
    content: '';
    width: 100%;
    position: fixed;
    bottom: 0;
    padding: 20px;
    height: 100px;
    background-color: $background-primary;
    box-shadow: 0px -5px 30px 30px $background-primary;
    z-index: 1;
  }
  &__secondary {
    &::before {
      background-color: $background-secondary;
      box-shadow: 0px 5px 30px 30px $background-secondary;
    }
    &::after {
      background-color: $background-secondary;
      box-shadow: 0px -5px 30px 30px $background-secondary;
    }
  }
}

@media (max-width: 800px) {
  .background {
    &::after {
      height: 150px;
    }
  }
}

.secondary {
  background-color: $background-secondary;
}

.green {
  color: $tearwings-green !important;
  i {
    filter: hue-rotate(227deg);
  }
  span {
    background: $tearwings-green;
  }
  &:hover {
    color: $tearwings-green;
  }
}
