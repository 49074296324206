.scroll-menu {
  top: 50%;
  transform: translateY(-50%);
  left: 50px;
  position: -webkit-sticky;
  position: fixed;
  width: 150px;
  display: flex;
  flex-direction: column;
  width: 31px;
  height: 441px;
  background-repeat: no-repeat;
  background-image: url('./images/play-menu.svg');
  cursor: pointer;
  z-index: 5;
}

.scroll-menu__top {
  width: 41px;
  height: 32px;
  position: absolute;
  top: -40px;
  left: -5px;
  background-repeat: no-repeat;
  background-image: url('./images/play-menu-top.svg');
}

.scroll-menu:hover {
  .scroll-menu__buttons {
    display: block;
    animation: scrollMenuOpen 300ms ease-out forwards;
  }
}

.scroll-menu:not(:hover) {
  .scroll-menu__buttons {
    //display: none;
    animation: scrollMenuClose 300ms ease-in forwards;
  }
}

// Filling
.scroll-menu__fill {
  position: absolute;
  top: 7px;
  left: 16px;
  background-color: $green-highlight;
  width: 3px;
  height: 0px;
  //height: 422px;
}

.scroll-menu__fillers {
  position: absolute;
  top: 5px;
  left: 4px;
}

.scroll-menu__filler {
  width: 25px;
  height: 36px;
  background-repeat: no-repeat;
  background-image: url('./images/play-menu-filler.svg');
  margin-bottom: 96px;
  visibility: hidden;
}

//

.scroll-menu__buttons {
  position: absolute;
  top: 9px;
  padding-left: 45px;
  height: 100%;
}

.scroll-menu__item {
  font-family: 'Jura', sans-serif;
  margin-bottom: 106px;
  font-size: 18px;
  color: $text-dark;
  text-shadow: -2px 2px $shadow;
  display: block;
  span {
    margin-top: 3px;
    display: block;
    width: 0%;
    height: 1px;
    background: $text-dark;
    opacity: 0.75;
  }
}

.scroll-menu__item:hover {
  span {
    animation: bottomLine 150ms ease-out forwards;
  }
}

@media (max-width: 800px) {
  .scroll-menu {
    display: none;
  }
}

@keyframes scrollMenuOpen {
  0% {
    opacity: 0;
    transform: translateX(-50%);
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes scrollMenuClose {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translateX(-50%);
  }
}
