body {
  //background-color: $background-primary;
  //   overflow: hidden;

  //Google fonts
  // font-family: 'Audiowide', cursive;
  // font-family: 'Gochi Hand', cursive;
  font-family: 'Josefin Sans', sans-serif;
  // font-family: 'Jura', sans-serif;
  // font-family: 'Lato', sans-serif;
  // font-family: 'Play', sans-serif;
}

.main-container {
  display: flex;
  flex-direction: column;
  background-color: $background-primary;
  position: relative;
  min-height: 100vh;
  min-width: 800px;
}

@media (max-width: 800px) {
  .main-container {
    min-width: 100%;
  }
}

.gradient-effect {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: radial-gradient(circle at center, white 0%, $background-secondary 90%);
}

.header {
  width: 100%;
  padding: 20px 0 0 20px;
  height: 120px;
  position: fixed;
  padding-left: 100px;
  top: 0;
  z-index: 3;
}

@media (max-width: 800px) {
  .header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    padding-top: 10px;
  }
}

.contents {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-direction: column;
}

.contents-main {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.menu {
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  height: 100px;
  z-index: 4;
}

.navigation {
  width: 33%;
}

.navigation__top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
  height: 25px;
  padding-left: 80px;
}

.navigation__bottom-row {
  color: $text-dark;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Jura', sans-serif;
  text-decoration: underline;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  padding-left: 80px;
}

@media (max-width: 800px) {
  .menu {
    height: auto;
  }
  .navigation {
    width: 100%;
  }

  .navigation__top-row {
    padding-left: 20px;
    flex-direction: column;
    height: 100%;
  }

  .navigation__bottom-row {
    padding-left: 20px;
    flex-direction: column;
  }
}

//Pages
.intro-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.embla-on-right {
  position: absolute;
  right: 0;
  top: 0;
}
