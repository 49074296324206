@import '../scss/designtokens';

@mixin diamond {
  content: '';
  display: block;
  width: 7px;
  height: 7px;
  background: black;
  top: 32px;
  position: absolute;
  transform: rotate(45deg);
}

.title {
  text-align: center;
  margin: 5rem -125px;
  width: 250px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 50%;
  border-bottom: solid black 1px;
  padding: 4px;
  color: $text-dark;

  &::before {
    @include diamond;
    left: -4px;
  }
  &::after {
    @include diamond;
    right: -4px;
  }
}
.bg {
  position: absolute;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  background-image: url('../scss/images/background-1.webp');
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: contain;
  z-index: 1;
}

.main {
  z-index: 1;
}

.content {
  max-width: 900px;
  width: 100%;
  overflow-y: scroll;
  font-size: 18px;
  color: #3c3153;
  height: calc(100vh - 280px);
  position: relative;
  scrollbar-color: #7a62ab #e4d8f0;
  scrollbar-width: thin;
  p {
    margin: 3rem 0;
  }
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #e4d8f0;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background: #7a62ab;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #7a62ab;
  }
  &::before {
    content: '';
    width: 100%;
    background-color: $background-primary;
    height: 10px;
    position: fixed;
    top: 140px;
    box-shadow: 0px 5px 30px 30px $background-primary;
  }
  &::after {
    content: '';
    width: 100%;
    background-color: $background-primary;
    height: 10px;
    position: fixed;
    bottom: 140px;
    box-shadow: 0px 5px 30px 30px $background-primary;
  }
}

@media (max-width: 800px) {
  .content {
    height: calc(100vh - 400px);
    &::before {
      height: 60px;
    }
    &::after {
      height: 70px;
    }
  }
}

@media (max-width: 800px) {
  .title {
    margin-top: 8rem;
    z-index: 1;
  }
  .content {
    p {
      padding: 0px 32px;
    }
  }
}

.paragraph-title {
  font-family: 'Play', sans-serif;
  font-weight: bold;
}
