.icon {
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 24px;
  display: inline-block;
}

.icon--diamond {
  width: 17px;
  height: 17px;
  background-image: url(./images/diamond.svg);
}

.icon--diamond:hover {
  background-image: url(./images/diamond-hover.svg);
}
