@import '../scss/designtokens';

.background {
  min-height: 100vh;
  width: 100vw;
  background: white;
  background: url('../scss/images/background-3.webp'), radial-gradient(circle, white 0%, #f0fffe 100%);
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
}
.content {
  height: 100%;
  width: 100%;
  max-width: 700px;
  text-align: center;
  font-family: 'Jura', sans-serif;
  padding: 1rem;
  box-sizing: border-box;
}
.icon {
  width: 100%;
  max-width: 400px;
  min-height: 280px;
}
.title {
  font-family: 'Play', sans-serif;
  font-size: 26px;
  color: $text-light;
  font-weight: bold;
  margin: 0;
}
.paragraph {
  font-size: 16px;
  color: $text-dark;
  font-weight: bold;
  margin-bottom: 1.5rem;
}
.button-main {
  background: url('../scss/images/button-bg-1.png');
  background-repeat: no-repeat;
  display: inline-flex;
  width: 18rem;
  background-size: cover;
  aspect-ratio: 6 / 1;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s, transform 0.3s;
  margin: 1rem 0;
  span {
    font-family: 'Play', sans-serif;
    color: white;
    font-weight: bold;
  }
  &:hover {
    opacity: 0.7;
    transform: translate(0px, -5px);
  }
}

@media (max-width: 800px) {
  .icon {
    height: 200px;
    width: auto;
    min-height: auto;
  }
}
