.roadmap-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 190px 0px;
}

@media (max-width: 800px) {
  .roadmap-container {
    .text-title,
    .text-title-divider {
      display: none;
    }
    margin: 250px 0px;
  }
}

.roadmap-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 600px;
}

@media (max-width: 800px) {
  .roadmap-row {
    width: 350px;
    flex-direction: column;
  }
}

.roadmap-row__date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Play', sans-serif;
  font-weight: bold;
  margin-right: 45px;
  color: $text-dark;
  text-shadow: -2px 2px $shadow;
  line-height: 0.8;
}

@media (max-width: 800px) {
  .roadmap-row__date {
    margin-right: 0px;
    margin-bottom: 8px;
  }
}

.date-completed {
  position: absolute;
  width: 161px;
  height: 58px;
  background-repeat: no-repeat;
  background-image: url('./images/date-completed.svg');
}

.roadmap-row__year {
  font-size: 29px;
}

.roadmap-row__quarter {
  font-size: 50px;
}
@mixin roadmap-bars-end {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  bottom: -8px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 6px solid $green-highlight;
}

.roadmap-bars {
  height: 200px;
  width: 50px;
  margin-top: -190px;
  align-self: baseline;
  margin-left: 10px;
  display: flex;
  justify-content: space-around;
  :first-child {
    margin-top: -30px;
    &::before {
      @include roadmap-bars-end;
    }
  }
  :last-child {
    margin-top: -60px;
    &::before {
      @include roadmap-bars-end;
      transform: rotate(180deg);
    }
  }
  span {
    position: relative;
    display: block;
    height: 100%;
    width: 6px;
    background-color: $green-highlight;
  }
}

@media (max-width: 800px) {
  .roadmap-bars {
    align-self: center;
    margin-left: 0px;
    margin-bottom: 8px;
  }
}

.roadmap-row-connector {
  height: 50px;
  width: 6px;
  background-color: $green-highlight;
  align-self: flex-start;
  margin: 10px 0px 10px 31px;
}

@media (max-width: 800px) {
  .roadmap-row-connector {
    align-self: center;
    margin: 8px 0px;
  }
}

.roadmap-row__objectives {
  width: 100%;
  li {
    position: relative;
    margin-bottom: 12px;
  }
}

.goal-completed {
  position: absolute;
  right: 0;
  width: 74px;
  height: 27px;
  transform: translateY(-22px) translateX(50%);
  background-repeat: no-repeat;
  background-image: url('./images/goal-completed.svg');
}
