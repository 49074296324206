@import '../scss/designtokens';

.tearwings {
  position: relative;
  z-index: 2;
  height: 900px;
  &__background {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    z-index: -1;
    overflow: hidden;
    &__content {
      width: fit-content;
      height: 100%;
      aspect-ratio: 10 / 2;
    }
  }
  &__foreground {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    pointer-events: none;
    z-index: 1;
  }
  &__mobile_bg {
    background-image: url('../scss/images/tearwings-mobile.png');
    background-position: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: -1;
  }
  &__icon {
    max-width: 600px;
    width: 100%;
  }
  &__highlight {
    color: $tearwings-green;
  }
  &__title {
    font-family: 'Lato', 'sans-serif';
    padding: 2rem 0;
  }
  &__content {
    font-family: 'Lato', 'sans-serif';
    color: $background-primary;
    line-height: 1.3;
  }
  &__list {
    font-family: 'Lato', 'sans-serif';
    color: $background-primary;
    li {
      margin-top: 10px;
    }
    li::marker {
      content: url('../scss/images/tearwings-list-icon.svg');
    }
  }
}
