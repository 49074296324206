@import '../scss/designtokens';

.green {
  color: $tearwings-green;
  span {
    background: $tearwings-green;
  }
  &:hover {
    color: $tearwings-green;
  }
}

.sai-icon {
  max-width: 600px;
  width: 100%;
}

.sai-bg {
  background-image: url('../scss/images/saig.webp');
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  height: 600px;
  aspect-ratio: 2 / 2;
  top: 180px;
}

@media (max-width: 800px) {
  .sai-bg {
    width: 100%;
    height: auto;
  }
}

.embla-wired {
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 2;
  height: 100vh;
  width: 380px;
  pointer-events: none;
  &__content {
    position: absolute;
    width: 1280px;
    height: 100%;
    left: -450px;
  }
}

@media (max-width: 800px) {
  .embla-wired {
    display: none;
  }
}

.main-section {
  height: 100vh;
  width: 100%;
  z-index: 3;
  background-image: radial-gradient(circle at center, white 0%, $background-secondary 60%);
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: 1;
  background-position: center;
  box-shadow: 0px -5px 30px 50px $background-secondary;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 3rem;
  box-sizing: border-box;
  &__bg {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    padding-right: 15px;
    overflow: hidden;
    &__content {
      width: fit-content;
      height: 100%;
      aspect-ratio: 7 / 2;
    }
  }
  &__title {
    font-family: 'Play', sans-serif;
    color: $text-light;
    font-size: 4rem;
    margin: 0;
  }
  &__subtitle {
    font-family: 'Play', sans-serif;
    color: $text-light;
    font-size: 1.5rem;
    margin: 1rem;
  }
  &__scroll {
    position: relative;
    font-family: 'Josefin Sans', sans-serif;
    color: $text-light;
    font-size: 1.2rem;
    width: 100px;
    margin: 0 auto;
    span {
      position: absolute;
      left: 1.8rem;
    }
  }
}
