@import '../scss/designtokens';

.background {
  position: absolute;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  padding-left: 15px;
  overflow: hidden;
  z-index: 1;
  &__content {
    width: fit-content;
    height: 100%;
    aspect-ratio: 7 / 2;
  }
}

.link {
  z-index: 1;
  height: 4rem;
  position: relative;
  aspect-ratio: 7 / 2;
  span {
    font-family: 'Josefin Sans', sans-serif;
    color: #7a62ab;
    position: absolute;
    top: 1.6rem;
    left: 4.4rem;
    font-size: 0.8rem;
  }
}
