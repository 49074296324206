.team-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 220px 0px;
  max-width: 1280px;
  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../scss/images/background-1.webp');
    z-index: 1;
    pointer-events: none;
  }
}

@media (max-width: 800px) {
  .team-container {
    margin: 240px 0px;
  }
}

.team-top {
  display: flex;
  flex-direction: row;
  padding: 0px 100px;
  margin-bottom: 30px;
  gap: 10%;
  align-items: flex-start;
}

.team-top__left {
  width: 50%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 20%, rgba(234, 235, 255, 0.5) 100%);
  border-right: 1px solid rgba(234, 235, 255, 1);
  border-radius: 1rem;
  padding: 2rem;
  li {
    margin-left: -20px;
  }
}

.team-top__right {
  width: 50%;
  padding-top: 2rem;
  .text-title-divider {
    margin-left: -13px;
  }
}

.team-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 100px;
}

.team-bottom__partner-links {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  li {
    margin-left: -20px;
  }
}

@media (max-width: 800px) {
  .team-top {
    flex-direction: column;
    padding: 0px 20px;
    align-items: center;
  }
  .team-top__left,
  .team-top__right {
    width: 80%;
  }

  .team-bottom {
    padding: 0px 20px;
  }
}
