.main {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 8;
  pointer-events: none;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 1.5s ease-out;
  &--leave {
    opacity: 0;
  }
  &__mask {
    height: 400px;
    transition: transform 1.5s ease-in-out;
    transform: scale(0.2, 0.2);
    &--enter {
      transform: scale(20, 20);
    }
  }
}
