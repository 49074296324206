.text-title {
  font-family: 'Play', sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: $text-dark;
}

.text-title-divider {
  display: block;
  margin: 5px 0px 20px 0px;
  height: 1px;
  width: 330px;
  background-color: $text-dark;
}

.text-title-divider::before {
  display: block;
  width: 7px;
  height: 7px;
  background-color: $text-dark;
  transform: translateY(-3px) rotate(45deg);
  content: '';
}

.text-title-divider::after {
  display: block;
  width: 5px;
  height: 5px;
  background-color: $text-dark;
  transform: translateX(330px) translateY(-9px) rotate(45deg);
  content: '';
}

.text-body {
  font-family: 'Jura', sans-serif;
  font-size: 17px;
  font-weight: bold;
  color: $text-dark;
  text-shadow: -2px 2px $shadow;
  margin: 1rem;
}

.text-highlight {
  color: $text-light;
}

.text-list {
  font-family: 'Jura', sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: $text-dark;
  text-shadow: -2px 2px $shadow;
  text-align: left;
  margin: 0px;

  li {
    margin-top: 10px;
    padding-left: 8px;
  }

  li::marker {
    content: url('./images/bullet-point.svg');
  }
}

.text-list__columns {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.text-list__divider {
  display: block;
  margin: 12px 0px;
  height: 1px;
  width: 100%;
  background-color: $text-dark;
  opacity: 0.5;
}

.text-link {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: bold;
}

.text-position {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: normal;
}
