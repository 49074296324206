.embla-white-link {
  width: 100%;
  padding: 20px 0 0 20px;
  height: 120px;
  position: fixed;
  padding-left: 100px;
  top: 0;
  z-index: 5;
}

@media (max-width: 800px) {
  .embla-white-link {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    padding-top: 10px;
  }
}
