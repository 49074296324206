html {
  scroll-behavior: smooth;
}

.main-container--play {
  background-color: $background-secondary;
}

.header--play {
  background-color: $background-secondary;
  box-shadow: 0px 5px 30px 30px $background-secondary;
}

.menu--play {
  background-color: $background-secondary;
  box-shadow: 0px -5px 30px 30px $background-secondary;
}

.main-container--tearwings {
  animation: blackout 150ms ease-out forwards;
}

.header--tearwings {
  animation: blackoutTop 150ms ease-out forwards;
}

.menu--tearwings {
  background-color: $background-secondary;
  box-shadow: 0px -5px 30px 30px $background-secondary;
}

//Animations
@keyframes blackout {
  0% {
    background-color: $background-secondary;
  }
  100% {
    background-color: black;
  }
}

@keyframes blackoutTop {
  0% {
    background-color: $background-secondary;
    box-shadow: 0px 5px 30px 30px $background-secondary;
  }
  100% {
    background-color: black;
    box-shadow: 0px 5px 30px 30px black;
  }
}

@keyframes blackoutBottom {
  0% {
    background-color: $background-secondary;
    box-shadow: 0px -5px 30px 30px $background-secondary;
  }
  100% {
    background-color: black;
    box-shadow: 0px -5px 30px 30px black;
  }
}

.play-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  width: 100%;
  margin: auto;
  text-align: center;
  position: relative;
}

@media (max-width: 800px) {
  .play-container {
    padding-bottom: 80px;
  }
}

.play-one {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.play-two {
  @extend .play-one;
}

.play-three {
  @extend .play-one;
}

.play-four {
  @extend .play-one;
  margin-bottom: 0px;
}

.play-image {
  width: 300px;
  margin: 48px 0px 24px 0px;
}

#systems {
  margin-bottom: 100px;
}
