.embla-header-logo {
  width: 200px;
  height: 118px;
  background-repeat: no-repeat;
  background-image: url('./images/embla-header.svg');
}

button {
  border: none;
  background: none;
  cursor: pointer;
}

a {
  color: $text-dark;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
}

a:link {
  color: $text-dark;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: $text-light;
}

.button-enter {
  background-repeat: no-repeat;
  background-position: center;
  width: 160px;
  height: 32px;
  display: inline-block;
  font-size: 15px;
  color: $text-light;
  background-image: url(./images/click-enter.svg);
}

.button-menu {
  display: flex;
  align-items: flex-start;
  font-size: 18px;
  color: $text-dark;
  text-shadow: -2px 2px $shadow;
  i {
    margin-right: 4px;
  }
  span {
    display: block;
    width: 0%;
    height: 1px;
    background: $text-lightest;
  }
  div {
    padding-right: 2rem;
  }
}

.button-menu:hover {
  color: $text-light;
  i {
    background-image: url(./images/diamond-hover.svg);
  }
  animation: buttonMenuHover 150ms ease-out forwards;
  span {
    animation: bottomLine 300ms ease-out forwards;
  }
}

.button-menu--selected {
  @extend .button-menu:hover;
}

@keyframes buttonMenuHover {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-10%);
  }
}

@keyframes bottomLine {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
